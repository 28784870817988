import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/taxdebtoffersVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import CombinedForm from "@components/shared/combinedForm";

export default function MainBanner(): ReactElement {
    const { domain } = useDomainContext();

    return domain.categories?.length === 1 ? (
        <div className={styles["main-banner"]}>
            <Form
                classes={{
                    formClassName: styles["form"],
                    stepTitleClassName: styles["step-title"],
                    stepClassName: styles["step-block"],
                    stepDescriptionClassName: styles["step-description"],
                    formButtonClassName: styles["form-button"],
                    formFieldClassName: styles["form-field"],
                    formStepInnerClassName: styles["steps-inner"],
                    fieldsClassName: {
                        radio: styles["radio-field"],
                        checkbox: styles["checkbox-field"],
                    },
                    fieldsWrapperClassName: styles["fields-wrapper"],
                    tcpaClassName: styles["tcpa"],
                    labelAsTitleClassName: styles["label-as-step-title"],
                    sellingPointClassName: styles["selling-point"],
                }}
                colors={{
                    primaryColor: colors.primaryColor,
                    progressBar: colors.primaryColor,
                }}
                popupStyle={{
                    actionBtnBgColor: "#eb7f58",
                    actionBtnColor: "#fff",
                    iconColor: "#eb7f58",
                }}
                showProgress={true}
                preventWindowClose
            />
        </div>
    ) : (
        <CombinedForm
            formClasses={{
                // formClassName: styles["form"],
                formProgressbarClassName: styles["progress-bar"],
                stepTitleClassName: styles["step-title"],
                stepClassName: styles["step-block"],
                stepDescriptionClassName: styles["step-description"],
                formButtonClassName: styles["form-button"],
                formFieldClassName: styles["form-field"],
                formStepInnerClassName: styles["steps-inner"],
                fieldsClassName: {
                    radio: styles["radio-field"],
                    checkbox: styles["checkbox-field"],
                },
                fieldsWrapperClassName: styles["fields-wrapper"],
                tcpaClassName: styles["tcpa"],
                labelAsTitleClassName: styles["label-as-step-title"],
                sellingPointClassName: styles["selling-point"],
            }}
            mainBannerClassName={styles["main-banner"]}
            primaryColor={"#51a5d6"}
            colors={{
                primaryColor: colors.primaryColor,
                progressBar: colors.primaryColor,
            }}
            backButtonClassName={styles["back-button"]}
            blockClassName={styles.serviceBlockLnk}
            question="What do you need help with today?"
            resetLeadId
        />
    );
}
