import React, { ReactElement } from "react";
import MainBanner from "@components/taxdebtoffers/mainBanner";
import TaxDebtOffersWrapper from "@components/layouts/taxdebtoffersWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import MainWrapper from "@components/taxdebtoffers/mainWrapper";

const Benefits = dynamic(() => import("@components/taxdebtoffers/benefits"));
const WhyUs = dynamic(() => import("@components/taxdebtoffers/whyUs"));
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function TaxDebtOffersHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <TaxDebtOffersWrapper>
            <MainWrapper>
                <MainBanner />
            </MainWrapper>
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <section className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <Benefits />
            </section>
            <WhyUs />
        </TaxDebtOffersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
