import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function MainWrapper({ children }): ReactElement {
    return (
        <div className={styles["mainWrapper"]}>
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                {children}
            </div>
        </div>
    );
}
